var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "import-container" },
    [
      _c(
        "import-template",
        {
          ref: "import",
          attrs: {
            uploadUrl: _vm.uploadUrl,
            downloadUrl: _vm.downloadUrl,
            saveBefore: _vm.saveBefore,
            title: "导入房间号",
          },
        },
        [
          _c("div", { attrs: { slot: "remark" }, slot: "remark" }, [
            _vm._v(
              "\n      1、项目名称：必须是项目信息表中已有的项目名称；\n      "
            ),
            _c("br"),
            _vm._v("2、组团：苑，非必填；\n      "),
            _c("br"),
            _vm._v("3、楼幢：必填；\n      "),
            _c("br"),
            _vm._v("4、单元：非必填；\n      "),
            _c("br"),
            _vm._v("5、室：非必填；\n      "),
            _c("br"),
            _vm._v("6、所属楼层：必填，无值情况请给0；\n      "),
            _c("br"),
            _vm._v(
              "7、建筑类型：必填，多层, 小高层, 高层, 排屋, 别墅, 写字楼, 商场, 会所, 酒店, 停车场, 幼儿园, 商业（综合体）, 公共建筑, 标准办公（超甲级）, 标准办公（甲级）, 标准办公（乙级）,\n      园区办公（联合）, 独栋办公（<1万方）, 住宅（公寓）, 厂房, 其它； "
            ),
            _c("br"),
            _vm._v(
              "8、房屋类型：必填，公寓, 住宅, 排屋, 别墅, 商铺, 办公用房, 物业经营用房, 保姆房, 储藏室, 自行车库, 车库, 车位, 地下室,\n        其它；\n        "
            ),
            _c("br"),
            _vm._v("9、是否可售：必填，可出售, 不可出售；\n        "),
            _c("br"),
            _vm._v(
              "10、出售状态：必填，未出售, 售卖中, 出售锁定, 已出售；\n        "
            ),
            _c("br"),
            _vm._v("11、是否可租：必填，可出租, 不可出租；\n        "),
            _c("br"),
            _vm._v(
              "12、出租状态：必填，未出租, 招商中, 出租锁定, 已出租；\n        "
            ),
            _c("br"),
            _vm._v(
              "13、房屋状态：必填，空置, 未领, 空关, 装修, 入住；\n        "
            ),
            _c("br"),
            _vm._v("14、计费面积：必填，无值情况请给0；\n        "),
            _c("br"),
            _vm._v("15、建筑面积：必填，无值情况请给0；\n        "),
            _c("br"),
            _vm._v("16、套内面积：非必填；\n        "),
            _c("br"),
            _vm._v("17、分摊面积：非必填；\n        "),
            _c("br"),
            _vm._v("18、辅助面积：非必填；\n        "),
            _c("br"),
            _vm._v("19、统计面积：非必填；\n        "),
            _c("br"),
            _vm._v("请在excel中按以上顺序填写数据\n        "),
            _c("br"),
            _vm._v("最大支持10MB的excel文件，超过请拆分导入\n    "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }