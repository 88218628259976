// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}communityRoom/listCommunityRoom`
// 导出列表
const exportListURL = `${API_CONFIG.shopBaseURL}communityRoom/exportDelayCommunityRoomList`
// 获取组织列表
const getOrgListURL = `${API_CONFIG.butlerBaseURL}orgInfo/searchOrgByKeyword`
// 批量操作
const batchURL = `${API_CONFIG.baseURL}smUserAccountAction!batchUpdateRechargeCardStatus.action`
// 获取房屋类型
// const getCommunityRommTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getCommunityRommTypes.action`
// 获取树的节点
const getProjectTreeURL = `${API_CONFIG.butlerBaseURL}communityNode/getProjectTree`
// 获取房号详情
const getRoomParamByNodeIdURL = `${API_CONFIG.butlerBaseURL}communityNode/getRoomParamByNodeId`
// 获取批量修改节点数
const getUpdateRoomInfoBatchCountURL = `${API_CONFIG.butlerBaseURL}communityNode/getUpdateRoomInfoBatchByNodeCount`
// 批量修改
const updateRoomInfoBatchURL = `${API_CONFIG.butlerBaseURL}communityNode/updateRoomInfoBatchByNode`


// 新增房号
const saveRoomInfoURL = `${API_CONFIG.butlerBaseURL}communityRoom/saveRoomInfo`
// 更新房号
const updateRoomInfoURL = `${API_CONFIG.butlerBaseURL}communityRoom/updateRoomInfo`
// 获取房号详情
const getFullRoomInfoURL = `${API_CONFIG.butlerBaseURL}communityRoom/getFullRoomInfo`
// 删除菜单
const deleteMenuList = `${API_CONFIG.butlerBaseURL}communityRoom/deleteCommunityRoomNumIds`
// 获取苑列表
// const queryBlockNameFromCommunityURL = `${API_CONFIG.baseURL}serverRoomAction!queryBlockNameFromCommunity.action`
// 导入Url前
const uploadExistUrl = `${API_CONFIG.butlerBaseURL}communityRoom/importRoomExist`
// 导入Url
const uploadUrl = `${API_CONFIG.butlerBaseURL}communityRoom/importRoom`
// 导入Url
const roomUploadUrl = `${API_CONFIG.butlerBaseURL}communityRoomLouyu/importRoom`
// 上传
const uploadImageURL = `${API_CONFIG.uploadURL}?module=user`
// 获取房号的租金单位类型
const getCommunityRoomNumLeaseUnitURL = `${API_CONFIG.butlerBaseURL}communityRoomNumAudit/getCommunityRoomNumLeaseUnit`

// const butlerBaseURL = 'http://192.168.10.86:7020/manage/'
// 获取房号信息（有招商信息）
const getMerchantsRoomURL = `${API_CONFIG.butlerBaseURL}busiBuildingPub/getCommunityBusiHouseInfo`
// 修改招商信息 (只修改图片，租金和简介)
const updateMerchantsInfoURL = `${API_CONFIG.butlerBaseURL}busiBuildingPub/updateCommunityBusiHouse`
// 房号导入模板
const downloadURL = `${API_CONFIG.butlerBaseURL}communityRoomLouyu/downLoadImportRoomModel`

// NC户号导入接口
const ncHouseUploadURL = `${API_CONFIG.butlerBaseURL}communityRoom/importNCHouse`


export {
  getListURL,
  exportListURL,
  getOrgListURL,
  batchURL,
  // getCommunityRommTypesURL,
  getProjectTreeURL,
  getRoomParamByNodeIdURL,
  getUpdateRoomInfoBatchCountURL,
  updateRoomInfoBatchURL,
  saveRoomInfoURL,
  updateRoomInfoURL,
  getFullRoomInfoURL,
  // queryBlockNameFromCommunityURL,
  uploadExistUrl,
  uploadUrl,
  roomUploadUrl,
  uploadImageURL,
  getCommunityRoomNumLeaseUnitURL,
  getMerchantsRoomURL,
  updateMerchantsInfoURL,
  downloadURL,
  deleteMenuList,
  ncHouseUploadURL
}
