<template>
  <div class="import-container">
    <import-template ref="import" :uploadUrl="uploadUrl" :downloadUrl="downloadUrl" :saveBefore="saveBefore"
      title="导入房间号">
      <div slot="remark">
        1、项目名称：必须是项目信息表中已有的项目名称；
        <!-- <br>2、第三方OA系统房号：非必填，第三方OA系统房号对应的房号； -->
        <br>2、组团：苑，非必填；
        <br>3、楼幢：必填；
        <br>4、单元：非必填；
        <br>5、室：非必填；
        <br>6、所属楼层：必填，无值情况请给0；
        <br>7、建筑类型：必填，多层, 小高层, 高层, 排屋, 别墅, 写字楼, 商场, 会所, 酒店, 停车场, 幼儿园, 商业（综合体）, 公共建筑, 标准办公（超甲级）, 标准办公（甲级）, 标准办公（乙级）,
        园区办公（联合）, 独栋办公（<1万方）, 住宅（公寓）, 厂房, 其它； <br>8、房屋类型：必填，公寓, 住宅, 排屋, 别墅, 商铺, 办公用房, 物业经营用房, 保姆房, 储藏室, 自行车库, 车库, 车位, 地下室,
          其它；
          <br>9、是否可售：必填，可出售, 不可出售；
          <br>10、出售状态：必填，未出售, 售卖中, 出售锁定, 已出售；
          <br>11、是否可租：必填，可出租, 不可出租；
          <br>12、出租状态：必填，未出租, 招商中, 出租锁定, 已出租；
          <br>13、房屋状态：必填，空置, 未领, 空关, 装修, 入住；
          <br>14、计费面积：必填，无值情况请给0；
          <br>15、建筑面积：必填，无值情况请给0；
          <br>16、套内面积：非必填；
          <br>17、分摊面积：非必填；
          <br>18、辅助面积：非必填；
          <br>19、统计面积：非必填；
          <br>请在excel中按以上顺序填写数据
          <br>最大支持10MB的excel文件，超过请拆分导入
      </div>
    </import-template>
  </div>
</template>
<script>
import { ImportTemplate } from 'components/bussiness/index'
import { uploadUrl, uploadExistUrl } from './api'
export default {
  components: {
    ImportTemplate
  },
  data () {
    return {
      uploadUrl,
      uploadExistUrl,
      downloadUrl: '/static/excel/房号导入标准模板.xls'
    }
  },
  created () {
    console.log('进入导入')
    this.$setBreadcrumb('导入')
  },
  methods: {
    previous () {
      this.$router.go(-1)
    },
    async saveBefore () {
      let tip = 0
      const { storageList } = this.$refs.import
      console.log(storageList, 'storageList')
      const formData = new FormData()
      formData.append('type', '')
      formData.append('file', storageList[0].raw)
      if (storageList.length > 0) {
        await this.$axios.post(uploadExistUrl, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then((res) => {
          if (res.status === 100) {
            tip = res.data
          }
        })
        let onOff = false
        if (tip) {
          let content = `本次导入检测到相同房号数据（如慧享生活馆1幢1单元101室）${tip}条，继续导入会覆盖原数据，是否继续导入？`
          onOff = await this.$confirm(content)
        } else {
          onOff = true
        }
        return onOff
      } else {
        this.$message({
          type: 'error',
          message: '请选择需要上传的文件',
          center: true
        })
        return false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  position: absolute;
  top: 20px;
  right: 20px;

  :not(:last-child) {
    margin-right: 8px;
  }
}
</style>
